"use client"; // Error components must be Client Components

import { useEffect } from "react";

export default function Error({ error, reset }) {
	useEffect(() => {
		// Log the error to an error reporting service
		console.error(error);
	}, [error]);

	return (
		<div className="h-screen w-screen flex justify-center items-center flex-col gap-2">
			<h2 className="display-large">Something went wrong!</h2>
			<div className="w-full max-w-lg bg-red-200 p-4 text-center">
				<p className="text-red-500">
					{JSON.stringify(error.message || error)}
				</p>
			</div>
			<button
				onClick={
					// Attempt to recover by trying to re-render the segment
					() => reset()
				}
			>
				Try again
			</button>
		</div>
	);
}
